#vehicles-page__controls__types {
  display: flex;
  width: 100%;
}

#vehicles-page__controls__types li {
  display: flex;
  align-items: center;
}

#vehicles-page__controls__types {
  overflow-x: auto;
  min-height: 3rem;
}

#vehicles-page__controls__types > * {
  margin-right: 1rem;
}

#vehicle-page {
  display: flex;
  width: 100%;
  overflow-y: auto;
}

#list-page__items {
  padding: 0;
}

#vehicle-page__filters {
  display: flex;
  align-items: center;
  margin: 2.4rem 0;
  flex-wrap: wrap;
}

#vehicle-page__filters > * {
  margin: 0.5rem;
}

.vehicle-page__section {
  margin-right: 4.8rem;
}

.vehicle-page__section header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.vehicle-page__section h3 {
  margin-right: 4.8rem;
}

@media screen and (max-width: 900px) {
  #vehicle-page {
    flex-direction: column;
    padding: 1em;
  }
  .vehicle-page__section {
    margin-bottom: 2.4rem;
    margin-right: 0;
  }
}
