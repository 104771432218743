#alert-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#alert-popup__message {
  font-weight: bold;
}

#alert-popup__button {
  align-self: center;
  margin-top: 2.4rem;
}
