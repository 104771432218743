#select {
  position: relative;
  text-align: center;
  width: 100%;
}

#button {
  width: 100%;
}

#select__search {
  position: relative;
}

#select__search span {
  position: absolute;
  right: 1rem;
}

#select__search__input {
  width: 100%;
  height: 4rem;
  border-radius: 9px;
  box-shadow: 4px 0px 40px 0px rgba(126, 126, 126, 0.05);
  border: 1px solid #d2d2d2;
  padding: 13px 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 488px;
}

.fa-angle-down,
.fa-angle-up {
  font-size: 1.6rem !important;
}

#select__options {
  position: absolute;
  background-color: #fff;
  margin: 0.5rem 0;
  min-width: 100%;
  z-index: 10000 !important;
  box-shadow: 1px 2px 5px 0px var(--dark-color-transparent-50);
  overflow-y: auto;
  border-radius: 9px;
}

#select__options__search-bar {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--dark-color-transparent-50);
}

#select__options__search-bar:hover {
  cursor: text;
}

#select__options__search-bar:focus,
#select__options__search-bar:active {
  outline: none;
}

#clear__button {
  position: absolute;
  right: 3rem;
  top: 0;
  bottom: 0;
}
