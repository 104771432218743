.option {
  font-size: 1.4rem;
  padding: 0.5em;
}

.option-checkbox {
  font-size: 1.4rem;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.option-checkbox :first-child {
  text-align: left;
}

.option-checkbox .checkbox {
  flex-shrink: 0;
}

.option-checkbox:hover {
  cursor: pointer;
}

.option:hover {
  cursor: pointer;
  background-color: var(--primary-color);
  color: #fff;
}

.option:focus {
  background-color: var(--primary-color);
  color: #fff;
  outline: none;
}
