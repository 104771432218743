#confirm-action__buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 1.6rem;
}

.button--confirm {
  color: white;
  background-color: var(--success-color);
  border: none;
  padding: 1.2rem 2.4rem;
  font-weight: bold;
  box-shadow: 2px 2px 2px 0px var(--success-color-dark);
  transition: 0.2s;
  margin: 1rem;
  border-radius: 4px;
}

.button--confirm:hover {
  background-color: var(--success-color-light);
}
