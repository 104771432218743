.utilsContainer {
  height: 54px;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  background-color: #fbfbfb;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.utilsRow {
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  width: 70%;
  align-items: center;
  gap: 84px;
  border-radius: 9px;
  border: 0.5px solid #d2d2d2;
  background: #fff;
  color: #e5097f;
}

.utilsButton {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #e5097f;
}

.utilsFunctions {
  display: inline-flex;
  align-items: flex-start;
  gap: 32px;
  color: #e5097f;
}
