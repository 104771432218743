#notification {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.75em 1em;
  margin: 1em;
  transition: 0.4s;
  z-index: 1000;
}

.notification-show {
  opacity: 1;
}

.notification-hide {
  opacity: 0;
}

#notification__msg {
  color: white;
}

.notification-success {
  background-color: var(--success-color);
  box-shadow: 2px 2px 2px 0px var(--success-color-dark);
}

.notification-error {
  background-color: var(--error-color);
  box-shadow: 2px 2px 2px 0px var(--error-color-dark);
}

.notification-info {
  background-color: var(--primary-color);
  box-shadow: 2px 2px 2px 0px var(--primary-color-dark);
}
