#report-job-final {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

#report-job-final header {
  margin: 2.4rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
