#weekpicker {
  overflow: auto;
  z-index: 100000;
  background-color: var(--background-color);
  box-shadow: 1px 1px 2px 0px var(--dark-color-transparent-50);
  opacity: 1;
  animation-name: fade-in;
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  padding: 1.6rem;
  margin: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#weekpicker__controls {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.6rem 0;
  gap: 1rem;
  font-weight: 500;
}

#weekpicker__controls div {
  display: flex;
  align-items: center;
}

#weekpicker__weeks {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #0000001a;
  padding: 0.5rem;
}

#weekpicker__weeks ul {
  display: flex;
}

#weekpicker__weeks ul:not(:first-child):hover {
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}

#weekpicker__weeks li {
  padding: 0.75em 1.2em;
  text-align: right;
  width: calc(100% / 7);
}

.weekpicker__weeks__selected {
  background-color: var(--primary-color-lighter);
  font-weight: bold;
  border-radius: 5px;
}

.prev-month-day {
  opacity: 0.5;
}

.fa-angle-up,
.fa-angle-down {
  font-size: 2.4rem;
  font-weight: bold;
}

@media screen and (max-width: 460px) {
  #weekpicker {
    scale: 0.8;
  }
}

@media screen and (max-width: 360px) {
  #weekpicker {
    scale: 0.6;
  }
}

@media screen and (max-width: 280px) {
  #weekpicker {
    scale: 0.5;
  }
}
