#report-summary {
  display: flex;
  flex-direction: column;
}

#report-summary__no-reports,
#report-summary__no-images,
#report-summary__signature {
  margin-bottom: 2.4rem;
}

#report-summary h4 {
  border-bottom: 1px solid var(--dark-color);
  padding: 1rem 0;
  margin-bottom: 2.4rem;
}

#report-summary h3 {
  margin-bottom: 2.4rem;
}

#report-summary__report__day-info {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
}

.info {
  display: flex;
}

#report-summary__report__rows {
  display: flex;
  flex-direction: column;
}

.report-job__images__li {
  margin-right: 1rem;
}

.daily-report-container {
  background-color: white;
  padding: 10px;
  border: 1px solid var(--light-border-color);
  border-radius: 5px;
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
}

.daily-report-container:last-child {
  margin-bottom: 20px;
}

.report-summary-text {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

.report-summary-text span {
  font-weight: 400;
  padding-left: 10px;
}