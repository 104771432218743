#report-job header {
  padding: 2.4rem 0;
}

#report-job header p {
  font-size: 1.4rem;
}

#report-job__day-picker {
  margin-bottom: 2.4rem;
}

#report-job h3 {
  border-bottom: 1px solid var(--dark-color);
  padding: 1rem 0;
  margin-bottom: 2.4rem;
}

#report-job__day-information li {
  margin-bottom: 2.4rem;
  width: 100%;
}

#report-job__day-information ul {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#report-job__time {
  display: grid;
  row-gap: 1.6rem;
}

#report-job__time-heading {
  grid-column: 1;
  grid-row: 1;
}

#report-job__time-to {
  grid-column: 1;
  grid-row: 3;
}

#report-job__time-from {
  grid-column: 1;
  grid-row: 2;
}

#report-job__time-break {
  grid-column: 2;
  grid-row: 2;
}

#report-job__time-total {
  grid-column: 2;
  grid-row: 3;
}

.report-job__form__input {
  width: 100%;
  height: 4rem;
}

.report-job__form__date-picker__button {
  margin-right: 1.6rem;
  text-align: left;
  width: 100%;
  height: 4rem;
}

.report-job__form__li:last-child {
  text-align: right;
}

#report-job__rows {
  display: flex;
  flex-direction: column;
}

.report-job-time-row {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  align-items: flex-end;
  justify-content: space-between;
}
@media (max-width: 450px) {
  .report-job-time-row {
    flex-direction: column;
    align-items: center;
  }
}
