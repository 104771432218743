form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
  width: 200px;
  height: auto;
}

#login__main {
  align-items: center;
  display: flex;
}

#section-login {
  background-color: #fff;
  padding: 3.2rem;
  box-shadow: 1px 1px 2px 0px var(--dark-color-transparent-50);
  margin: 4.8rem 0;
}

#password-wrapper {
  position: relative;
  width: 100%;
}

#password-visibility {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
}

#password-visibility:hover {
  cursor: pointer;
}

#password-visibility:focus {
  outline-color: var(--dark-color);
}

input[type='submit'] {
  margin-top: 1em;
}

.error {
  color: var(--dark-color);
  margin: 0;
  font-size: 1.2rem;
  font-weight: bolder;
  align-self: flex-start;
}

.forgot-password {
  margin-top: 1.6rem;
  font-size: 1.2rem;
  color: var(--dark-color);
  text-decoration: none;
}

.forgot-password:hover {
  text-decoration: underline;
}

