#add-job__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2.4rem;
}

#add-job__form {
  width: 100%;
}

#add-job__form__ul {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.add-job__form__li {
  width: 100%;
  margin-bottom: 2.4rem;
}

#add-job__form__swap-place {
  display: flex;
  justify-content: center;
}

.add-job__form__input {
  width: 100%;
  height: 4rem;
}

#add-job__form__li-littra {
  display: flex;
  flex-direction: column;
}

#add-job__form__li-place {
  display: flex;
  flex-direction: column;
}

#button--add-littra {
  align-self: flex-end;
}

#add-job__form__li-place > *,
#add-job__form__li-littra > * {
  margin-bottom: 1.6rem;
}

/* #add-job__form__li-place .label {
    margin-top: 1.6rem;
} */

#button--new-place {
  align-self: flex-end;
}

#add-job__form__li-place #places {
  margin-top: 1.6rem;
}

#add-job__form__li-place #button--new-place {
  margin: 1rem;
}

#add-job__form__li-contact {
  display: flex;
  flex-direction: column;
}

#button--add-contact {
  margin: 1rem;
  align-self: flex-end;
}

.add-job__form__li:last-child input[type='submit'] {
  /* text-align: right;
    margin-left: auto;
    position: relative;
    padding-right: 2px; */
  width: 100%;
}

#add-job__form__date {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

/* if screen less than 450 pixels make class above a column */
@media (max-width: 450px) {
  #add-job__form__date {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
}

.add-job__form__date-date {
  margin-right: 1.6rem;
  text-align: left;
}

#add-job__form__equipment-types {
  display: flex;
  align-items: center;
}

#add-job__form__equipment-types li {
  display: flex;
  align-items: center;
}

#add-job__form__equipment-types :nth-child(2) {
  margin-right: 1rem;
}

.form__selected-items {
  width: 100%;
}

.form__selected-items__item {
  display: flex;
  justify-content: space-between;
  margin: 1rem 1rem;
  align-items: center;
}

.form__selected-items__item > * {
  margin-right: 2.4rem;
}

.textarea {
  width: 100%;
  font-style: inherit;
}

.pac-container {
  box-shadow: 1px 1px 2px 0px var(--dark-color-transparent-50);
  background-color: #fff;
  border-radius: 0;
}

.pac-icon {
  display: none;
}

.pac-item {
  padding: 0.5em;
}

.pac-item:hover {
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
}

.pac-item:hover .pac-item-query {
  color: white;
}

#other {
  height: 150px;
  overflow-y: scroll;
}
