#add-slinga {
  display: flex;
  flex-direction: column;
}

#add-slinga__button--add {
  align-self: flex-end;
  margin-top: 2.4rem;
}

.add-slinga__li {
  margin: 2.4rem 0;
}

#add-slinga > section {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
}

.name-slinga {
  margin: 0 0 1.6rem 0;
}

.name-slinga__input {
  width: 100%;
  height: 4.8rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.4rem;
  padding: 0 1.6rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #000000;
}
