#settings__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#settings__header h2 {
  font-size: 2.4rem;
}

#settings__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#save {
  align-self: flex-start;
  width: auto;
  margin: 2.4rem 0;
}

@media screen and (max-width: 800px) {
  #logout {
    position: fixed;
    bottom: 2.4rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
