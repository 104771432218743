.date {
  font-weight: bold;
}

.today__desktop {
  background-color: hsl(328, 86%, 81%);
}

#no-jobs {
  padding: 1rem 24px;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Open Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.date-container {
  padding: 16px 24px;
  width: 100%;
}

.date-container-green {
  background: rgba(31, 194, 17, 0.25);
}

.date-container-red {
  background: rgba(229, 9, 127, 0.15);
}

.date-container-job-ammount {
  margin-left: 10px;
  color: #464646;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.date-container-date {
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
