#time-picker {
  display: flex;
  align-items: center;
}

#hours,
#minutes {
  text-align: center;
  height: 4rem;
}
