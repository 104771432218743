tr {
  display: flex;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
}

tr td {
  padding: 0.75em 0;
  background-color: transparent;
  color: black;

  text-align: left;

  min-width: 120px;
}

.week-day-entry:nth-child(odd) {
  background: #ffffff;
}

.week-day-entry:nth-child(even) {
  background-color: rgba(239, 239, 239, 0.4);
}

table tr td {
  color: #000;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  display: flex;
  align-items: center;
  border: none;
}

table tr td:first-child {
  min-width: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

table tr td:nth-child(6) {
  /*littra*/
  padding-left: 25px;
}

.status-box {
  color: #fff;

  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 2px 8px;
  border-radius: 2px;
}

.icon {
  width: 15px;
  height: 15px;
}
