#pick-a-place {
  display: flex;
  flex-direction: column;
  width: 24rem;
}

#button--new-place {
  align-self: flex-end;
  margin: 1.6rem 0;
}
