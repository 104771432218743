#show-pdfs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.4rem;
  min-height: 150px;
}

#show-pdfs main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.4rem;
}

#checkboxes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
