.add-images__li {
  display: flex;
  align-items: flex-start;
}

.img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

#add-images__controls {
  align-self: flex-end;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#add-images__controls button {
  align-self: flex-end;
  margin-right: 1rem;
}

#add-images__input {
  display: none;
}

#add-images__ul {
  display: flex;
  flex-wrap: wrap;
  margin: 2.4rem 0;
}
