#filter-select-container {
  width: 400px;
  border-radius: 5px 0px 0px 5px;
  height: 50px;
  background: #f9f9f9;
  display: flex;
  flex-direction: row;
}

.filer-select-select {
  outline: none;
  background: transparent;
  border: 0;
}

.filer-select-select-section {
  position: relative;
  height: 100%;
  flex-grow: 1;
  display: flex;
  padding-right: 1rem;

  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px 0px 0px 5px;

  justify-content: center;
  align-items: center;

  overflow: hidden;
}
#filter-select-option {
  flex-grow: 1;
  display: flex;

  padding: 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: #000;

  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

#filter-select-option .filter-option {
  color: #000;

  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#filter-select-filter {
  flex-grow: 10;
  border-radius: 0px 5px 5px 0px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #f9f9f9;
  display: flex;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  align-self: stretch;
  position: relative;
}
#dropdown-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 20px;
  transform: translateY(-50%);
  cursor: pointer;
}

#filter-select-filter #input-field {
  width: 100%;
  height: 100%;
  border: 0;
  outline: none;
  background: transparent;
}

#filter-select-filter #filter-select-values {
  display: flex;
  flex-direction: column;

  align-items: center;
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  background: white;
  max-height: 400px;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

#filter-select-filter #filter-select-values .filter-select-value {
  width: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
#filter-select-filter #filter-select-values .filter-select-value:hover {
  background: #f9f9f9;
}
