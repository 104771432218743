#month {
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
}

#month__days {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  width: calc(8 * 100px);
  align-self: center;
}

.month__days__day {
  font-weight: bold;
  border-bottom: 2px solid var(--dark-color);
  padding: 0.5em;
  position: sticky;
  top: 0;
  background-color: var(--dark-color);
  color: #fff;
  /* height: 'fit-content'; */
}

.month__days__date {
  border-right: 1px solid var(--dark-color);
  border-bottom: 1px solid var(--dark-color);
  height: 75px;
  width: 100px;
  padding: 0.25rem;
}

.month__days__date:nth-child(8n + 1) {
  border-left: 1px solid var(--dark-color);
}

.month__days__day__job-count {
  font-size: 1rem;
}

.date {
  margin-bottom: 0.75em;
}

.job {
  font-size: 1.2rem;
}

.button--show-more {
  font-size: 1.2rem;
  margin-top: 0.8rem;
  padding: 0;
}

@media screen and (min-width: 1800px) {
  .month__days__date {
    height: 150px;
    width: 150px;
  }
  #month__days {
    grid-template-columns: repeat(8, minmax(150px, 1fr));
    width: calc(8 * 150px);
  }
}

@media screen and (min-width: 1050px) {
  #month h2 {
    text-align: center;
    margin-bottom: 2.4rem;
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 1050px) {
  .month__days__date {
    height: 10vw;
    width: 10vw;
  }
  #month__days {
    grid-template-columns: repeat(8, 1fr);
    width: calc(8 * 10vw);
    margin: 0 auto;
  }
}

@media screen and (max-width: 580px) {
  .month__days__date {
    height: 10vw;
    width: auto;
  }
  #month__days {
    grid-template-columns: repeat(8, 1fr);
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .month__days__date {
    height: 12vw;
  }
}

@media screen and (max-width: 1050px) {
  #month {
    margin-bottom: 6.4rem;
  }
}
