.flex-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.calender-mode-button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  width: 95%;
}

.calender-mode-button-group button {
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease-in-out;
  width: 50%;
}
.calender-mode-button-group button:nth-child(1) {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  border-right: 1px solid var(--primary-color);
  
}

.calender-mode-button-group button:nth-child(2) {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}


