#li--table {
  width: 100%;
}

.comment-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  margin: 1.5rem 0;
  padding: 0 2.4rem;
  gap: 0.4rem;
  width: 100%;
}

.comment__text {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.7rem;
  width: 100%;
}

.details__information {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
