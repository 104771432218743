#container {
  width: 100%;
  /* margin-left: 1.6rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 16px;
}

#filters {
  display: flex;
  gap: 1.6rem;
}

.filter {
  justify-content: center;
  align-items: center;
  display: flex;
}

.filters__selected-list {
  display: flex;
  flex-wrap: wrap;
  overflow-y: hidden;
  padding: 0.5rem 0;
}

.filters__selected-item {
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

#filter-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#filter-header img {
  margin-right: 0.5rem;
  width: 15px;
  height: 15px;
}

#filter-header h3 {
  color: #000;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0;
  margin: 0;
}

#filter-container {
  width: 400px;
  min-height: 100%;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #f9f9f9;
  overflow: hidden;
}

@media screen and (max-width: 800px) {
  #container {
    margin-left: 0;
    height: 100%;
  }
  #filters {
    flex: 1;
    margin: 0;
    flex-direction: column;
  }
  .filter {
    margin-bottom: 1.6rem;
  }
}
