.button--plus {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 47px;
    height: 47px;
    text-align: center;
    z-index: 1;
    position: fixed;
    bottom: 3rem;
    right: 2rem;
    background-color: var(--primary-color);
    font-size: 3rem;
    border-radius: 50%;
    box-shadow: 0px 4px 6px 0px #00000073;
}

.button--plus-focused {
    background-color: var(--primary-color-dark);
}

.plus-button-container {
    position: fixed; 
    right: 2rem;
    bottom: 8rem; 
    width: 92px;
    height: 72px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 4px 6px 0px #00000073;

    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    flex-direction: column;

    padding: 0.5rem;
}