.job-info {
  display: flex;
  justify-content: space-between;
  padding: 0.5em;
}

.jobs__job {
  border-bottom: 1px solid var(--dark-color);
  padding: 1em;
}

.category {
  font-weight: bold;
}

#no-jobs {
  text-align: center;
  font-size: 2rem;
}

.job-info:has(> .button--copy) {
  justify-content: flex-end;
}

@media screen and (max-width: 1050px) {
  #jobs {
    margin-bottom: 6.4rem;
  }
}
