#add-row__header {
  display: flex;
  flex-direction: column;
}

#add-row__form,
#add-row__form__ul {
  width: 100%;
}

#add-row__form__ul {
  display: flex;
  flex-direction: column;
}

.add-row__form__ul__li {
  margin-bottom: 1.6rem;
  padding: 1rem;
}

.add-row__form__ul__li:last-child {
  text-align: right;
  margin-left: auto;
  position: relative;
}
