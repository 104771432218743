#list-page__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#list-page h2 {
  font-size: 2.4rem;
}

#list-page__header__buttons {
  display: flex;
}

#list-page__header__buttons :nth-child(2) {
  margin-left: 1.6rem;
}

#list-page__search {
  background-color: inherit;
  font-size: 2rem;
  width: 100%;
  margin: 2.4rem 0;
  height: 2em;
  padding: 1em 0.5em;
}

#list-page__items {
  width: 100%;
  padding: 2.4rem;
  overflow-y: auto;
}

.list-page__items__item {
  margin-bottom: 1.6rem;
}

.list-page__items__item p {
  border-bottom: 1px solid var(--dark-color);
  display: inline;
}

.list-page__items__item:hover {
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  #list-page__header__buttons {
    position: fixed;
    right: 2.4rem;
    bottom: 3.2rem;
  }
}
