.weekday {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  border-bottom: 1px solid #0000001a;
  background-color: white;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}

.weekday-date-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.weekday-job-column {
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  width: 90%;
}

.today-day-name {
  font-size: 1.6rem;
  color: black;
}

.not-today-day-name {
  font-size: 1.6rem;
  color: #00000099;
}

.today-date-circle {
  width: 40px;
  height: 40px;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
}

.not-today-day-number {
  font-size: 1.4rem;
  color: black;
}

.job--mobile {
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  margin-bottom: 5px;
  gap: 1rem;
}

.job--mobile:hover {
  cursor: pointer;
}

.job--mobile-row-align-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.job--mobile-row-align-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.unfilled-icon {
  stroke: black;
  stroke-width: 40;
}

.info-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
}

.info-column p {
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  max-width: 170px;
  text-overflow: ellipsis;
  line-height: 2rem;
}

@media screen and (max-width: 450px) {
  .weekday {
    flex-direction: column;
  }

  .job--mobile-row-align-start{
    flex-direction: column;
    gap: 2rem;
  }

  .job--mobile-row-align-center{
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }
}