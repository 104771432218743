#daypicker {
  overflow: auto;
  z-index: 10000;
  background-color: #fff;
  box-shadow: 1px 1px 2px 0px var(--dark-color-transparent-50);
  opacity: 1;
  animation-name: fade-in;
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  padding: 1.6rem;
  margin-top: 0.8rem;
  position: absolute;
}

.fa-angle-up,
.fa-angle-down {
  font-size: 2.4rem;
  font-weight: bold;
}

#daypicker__controls {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

#daypicker__days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

#daypicker__days li:nth-child(-n + 7) {
  padding: 0.75em;
  text-align: center;
}

#daypicker__days button {
  width: 100%;
  padding: 0.75em;
  text-align: center;
}

#daypicker__days button {
  transition: 0.2s;
}

.daypicker__days__selected {
  background-color: var(--primary-color);
  color: #fff;
}

.prev-month-day {
  opacity: 0.5;
}

#daypicker__days button:hover {
  background-color: var(--primary-color);
  color: #fff;
}

#daypicker__days button:focus {
  background-color: var(--primary-color);
  color: #fff;
  outline: none;
}

@media screen and (max-width: 800px) {
  #daypicker {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
