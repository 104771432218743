#navigation button {
  font-weight: bold;
}

img {
  width: 200px;
  height: auto;
}

#calendar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100vw;
}

#calendar__controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: var(--background-color);
}

#calendar__controls__right,
#calendar__controls__left {
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

#calendar__controls__left__filter {
  flex-shrink: 0;
}

#calendar__controls__right__admin-settings {
  display: flex;
  gap: 1.6rem;
}

#calendar__controls__right__admin-settings__days {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

#calendar__controls__right__admin-settings__days .checkbox {
  height: 1em;
  width: 1.25em;
}

#add-job-or-slinga-button-group {
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
}

.fa-angle-left,
.fa-angle-right,
.fa-angle-down {
  font-weight: bold;
  font-size: 2rem;
}

.fa-calendar,
.fa-plus {
  margin-right: 1rem;
}

.fa-angle-left,
.fa-angle-right {
  font-size: 2rem;
  padding: 0.5rem 2rem;
}

@media screen and (max-width: 1050px) {
  #calendar__controls {
    padding: 0;
  }

  #calendar__controls__left {
    z-index: 1;
    width: 100%;
    background-color: var(--background-color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5em;
    align-items: center;
  }
  #calendar__controls__left__filter-and-mode {
    display: flex;
    justify-content: space-between;
    width: 150px;
  }
  #calendar__controls__left__filter-and-mode #select__options {
    bottom: 100%;
  }
  .fa-angle-left,
  .fa-angle-right {
    font-size: 2rem;
  }
}

#loading-icon {
  /* Center icon */
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  color: #e1097c;
}
