#navigation {
  padding: 0.75em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-bottom: 0.5px solid #d2d2d2;
}

#navigation-mobile {
  padding: 0.75em;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 1px 1px 6px 0px var(--dark-color-transparent-50);
  gap: 1rem;
  border-bottom: 1px solid #0000001a;
}

#navigation-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#navigation--pointer {
  cursor: pointer;
}

#navigation__menu {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: left;
  position: fixed;
  top: 0;
  right: 0;
  background-color: var(--dark-color);
  color: white;
  font-weight: 600;
  z-index: 1000;
  padding: 1em 1em;
  font-size: 2rem;
  text-align: center;
}

#navigation__menu ul {
  padding: 1em;
}

.navigation__menu--hidden-anim {
  animation: slide-out 0.4s ease-out;
  transform: translateX(100%);
}

.navigation__menu--hidden {
  transform: translateX(100%);
}

.navigation__menu--visible {
  animation: slide-in 0.4s ease-out;
  box-shadow: 5px 0px 40px 0px var(--dark-color-transparent-75);
}

.navigation__menu__item {
  margin-top: 1em;
  height: 2em;
}

.navigation__menu__button {
  color: white;
  font-size: 2rem;
}

.navigation__menu__button:hover,
.navigation__menu__button:focus,
.navigation__menu__button--active {
  border-bottom: 4px solid var(--primary-color);
  outline: none;
}

#navigation__menu__button--close {
  color: white;
  text-align: right;
  width: 3.2rem;
  height: 3.2rem;
  position: absolute;
  right: 1em;
}

#navigation__menu__button--close:hover {
  color: white;
}

#navigation__menu__button--close:focus {
  outline-width: 3px;
  outline-style: solid;
}

#overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  overflow-y: auto;
  padding: 6.4rem 0;
}

/* .fa-close {
    
    color: white;
} */

@keyframes slide-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@media screen and (max-width: 290px) {
  #navigation-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
}
