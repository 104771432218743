#date-picker {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 9px;
  border: 1px solid #d2d2d2;
  background: #fff;
  box-shadow: 4px 0px 20px 0px rgba(126, 126, 126, 0.05);
  align-items: center;
}

#date-picker__buttons {
  display: flex;
  /* border-left: 1px solid #0000001a;
  border-right: 1px solid #0000001a; */
  padding: 1rem;
  width: 100%;
  justify-content: space-between;
  /* min-width: 20rem; */
}

@media screen and (max-width: 800px) {
  #date-picker__picker {
    position: fixed;
    top: 6.4rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

#date-picker__picker__controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 0;
}

#date-picker__picker__controls div {
  display: flex;
  align-items: center;
}

#date-picker__picker__controls p {
  font-style: italic;
}

.fa-angle-up,
.fa-angle-down {
  font-size: 2.4rem;
  font-weight: bold;
}
