#monthpicker {
  overflow: auto;
  z-index: 10000;
  background-color: #fff;
  box-shadow: 1px 1px 2px 0px var(--dark-color-transparent-50);
  opacity: 1;
  animation-name: fade-in;
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  padding: 1.6rem;
  margin-top: 0.8rem;
  position: absolute;
}

@media screen and (max-width: 800px) {
  #monthpicker {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

#monthpicker__controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 0;
}

#monthpicker__controls :first-child {
  margin-right: auto;
  font-style: italic;
}

#monthpicker__months {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

#monthpicker__months button {
  padding: 1em 1.5em;
  width: 100%;
}

#monthpicker__months button:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.monthpicker__months__selected {
  background-color: var(--primary-color);
  color: #fff;
}

.fa-angle-up,
.fa-angle-down {
  font-size: 2.4rem;
  font-weight: bold;
}

.fade-in {
  animation-name: fade-in;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
