table {
  border-collapse: separate;
}
table#week {
  table-layout: auto;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  padding: 16px;
  padding-top: 0;

  height: 70vh;
  overflow: scroll;
}

#week-container {
  width: 100%;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  /* height: calc(100% - 77px); */
  overflow-x: hidden;
}

#week-box {
  width: 100%;
  /* border-radius: 10px; */
  background: #fbfbfb;
  /* height: 95%; */
  overflow-y: scroll;
  margin: 40px;
}
#week-box table thead {
  position: sticky;
  top: 0rem;
}
#week-box table thead tr {
  display: flex;
}
#week-box table thead tr th {
  padding: 0.75em 0;
  width: 100%;
  color: #999;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#week-box table thead tr th.desk-week:first-child {
  border-radius: 10px 0px 0px 0px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 50px;
  display: flex;
  align-items: center;
  padding-left: 24px;
}

#week-box table thead tr th.desk-week:nth-child(2) {
  /*Order nr*/
  min-width: 100px;
}

#week-box table thead tr th.desk-week:nth-child(6) {
  /*littra*/
  padding-left: 25px;
}

#week-box table thead tr th.desk-week:nth-child(7) {
  /*Timmar*/
  min-width: 100px;
}

#week-box table thead tr th.desk-week:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px 10px 0px 0px;
  color: #f9f9f9;
}

table thead tr th.desk-week {
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  color: #000;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  display: flex;
  align-items: center;
}

table thead tr th.desk-week .logo {
  width: 20px;
  height: 15px;
}
#labels {
  border-radius: 10px 10px 0px 0px !important;
  border-bottom: 1px solid #e5e5e5;
  background: #f9f9f9;
}

td {
  padding: 0.75em 0;
  border-bottom: 1px solid var(--dark-color);
}

.primary-button-week {
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #e5097f;

  color: #fff;

  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.secondary-button-week {
  border-radius: 9px;
  border: 1px solid #e5097f;
  color: #e5097f;
  background: #fff;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.secondary-button-week img {
  width: 20px;
  height: 20px;
  color: #e5097f;
}

.primary-button-week img {
  width: 20px;
  height: 20px;
}

.primary-button-pdf {
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: rgba(0, 128, 74, 1);
  color: #fff;

  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.primary-button-pdf img {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 1050px) {
  #week {
    padding-bottom: 16rem;
  }

  #week-container {
    overflow: auto;
    overflow-x: hidden;
    background-color: white;
  }
}

#week-mobile {
  width: 100vw;
}
