#table-wrapper {
  overflow-x: auto;
  width: 100%;
}

#table {
  width: 100%;
  color: black;
  border-collapse: separate;
  border-spacing: 0;
}

#table thead {
  position: static;
}

#table thead tr {
  display: block;
  width: 100%;
}

#table thead th {
  padding: 0.5rem;
  background-color: rgba(234, 234, 234, 1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: black;
  font-weight: 600;
}

#table thead th:first-child {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

#table thead th:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

#table thead tr:first-child th:first-child {
  border-radius: 5px 0 0 0;
}

#table thead tr:first-child th:last-child {
  border-radius: 0 5px 0 0;
}

#table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 5px;
}

#table tbody tr:last-child td:last-child {
  border-radius: 0 0 5px 0;
}

#table tbody tr td:first-child {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

#table tbody tr td:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

#table tbody tr:last-child td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

tr {
  background-color: white;
}

.tr-clickable:hover {
  cursor: pointer;
}

#table td {
  max-width: 75px;
  padding: 0.8rem 1.6rem 0.8rem 0.4rem;
}

#table .empty-table__td {
  max-width: max-content;
}
